const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem 0.75rem',
    borderBottom: '1px solid #f5f5f5'
  },
  owner: {
    container: { marginLeft: '0.75rem' },
    image: {
      borderRadius: '100%',
      height: '3rem'
    },
    username: { fontWeight: 'bold' },
    location: { display: 'block' }
  }
}

export default styles
