// TODO - This feels messy.

export function handleLike (
  viewerHasLiked,
  setViewerHasLiked,
  totalLikes,
  setTotalLikes
) {
  if (viewerHasLiked) {
    setViewerHasLiked(false)

    if (totalLikes && setTotalLikes) {
      setTotalLikes(totalLikes - 1)
    }
  } else {
    setViewerHasLiked(true)
    if (totalLikes && setTotalLikes) {
      setTotalLikes(totalLikes + 1)
    }
  }
}
