const styles = {
  button: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: '0',
    cursor: 'pointer'
  }
}

export default styles
