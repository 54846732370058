export function getUsernameFromData (data) {
  return data.owner.username
}

export function getProfilePicFromData (data) {
  return data.owner.profile_pic_url
}

export function getAltTextFromData (data) {
  return data.owner.username
}

export function getLocationFromData (data) {
  return data.location.name
}
