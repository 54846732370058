import styles from './styles'
import ProfilePicture from './children/ProfilePicture'
import Username from './children/Username'
import Location from './children/Location'

const UserBlock = (props) => {
  const { data } = props

  console.log(data)
  return (
    <div style={styles.container}>
      <ProfilePicture data={data} />
      <div style={styles.owner.container}>
        <Username data={data} />
        <Location data={data} />
      </div>
    </div>
  )
}

export default UserBlock
