const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.3rem 1rem'
  },
  stack: { display: 'flex', flexDirection: 'column' }
}

export default styles
