import React from 'react'
import { commentFormatter } from '../helpers'
import styles from '../styles'

const Caption = (props) => {
  const { username, comment } = props

  return (
    <li style={styles.container}>
      <p style={styles.content}>
        <a href={'/users/' + username} style={styles.username}>
          {username}
        </a>
        {commentFormatter(comment)}
      </p>
    </li>
  )
}

export default Caption
