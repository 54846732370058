export function getLikeCount (data) {
  return data.edge_media_preview_like.count
}

export function getDaysSinceTimestamp (data) {
  const ts = data.taken_at_timestamp
  const d = new Date() // Gets the current time
  const nowTs = Math.floor(d.getTime() / 1000)
  // getTime() returns milliseconds, and we need seconds, hence the Math.floor and division by 1000
  const seconds = nowTs - ts

  // more than 1 year
  if (seconds > 365 * 24 * 3600) {
    return 'more than a year ago'
  }

  // more than 30 days
  if (seconds > 30 * 24 * 3600) {
    return 'a few days ago'
  }

  // more that two days
  if (seconds > 2 * 24 * 3600) {
    return 'a few days ago'
  }
  // a day
  if (seconds > 24 * 3600) {
    return 'yesterday'
  }

  if (seconds > 3600) {
    return 'a few hours ago'
  }
  if (seconds > 1800) {
    return 'Half an hour ago'
  }
  if (seconds > 60) {
    return Math.floor(seconds / 60) + ' minutes ago'
  }
}
