import Caption from './children/Caption'
import Comment from './children/Comment'
import styles from './styles'

const CommentBlock = (props) => {
  const { data } = props
  const comments = data.edge_media_to_comment.edges

  return (
    <ul style={styles.list}>
      <Caption
        username={data.owner.username}
        comment={data.edge_media_to_caption.edges[0].node.text}
      />

      {comments.map((comment, index) => {
        return (
          <Comment
            key={index}
            username={comment.node.owner.username}
            comment={comment.node.text}
          />
        )
      })}
    </ul>
  )
}

export default CommentBlock
