const styles = {
  container: {
    display: 'flex',
    alignItems: 'start',
    margin: '0.3rem 0',
    justifyContent: 'space-between'
  },
  username: {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'black',
    marginRight: '0.3rem'
  },
  content: { margin: '0', paddingRight: '1rem' },
  list: { overflowY: 'scroll', padding: '1rem', flex: 1, margin: 0 },
  hashtags: { color: 'blue', textDecoration: 'none' }
}

export default styles
