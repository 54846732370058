import React, { useState } from 'react'
import LikedIcon from './children/liked_icon'
import UnlikedIcon from './children/unliked_icon/'
import { handleLike } from './helpers'
import styles from './styles'

const LikeButton = (props) => {
  const { data } = props
  const [viewerHasLiked, setViewerHasLiked] = useState(data?.viewer_has_liked)

  return (
    <>
      {viewerHasLiked
        ? (
          <button
            onClick={() =>
              handleLike(
                viewerHasLiked,
                setViewerHasLiked,
                props.totalLikes,
                props.setTotalLikes
              )}
            style={styles.button}
          >
            <LikedIcon />
          </button>
          )
        : (
          <button
            onClick={() =>
              handleLike(
                viewerHasLiked,
                setViewerHasLiked,
                props.totalLikes,
                props.setTotalLikes
              )}
            style={styles.button}
          >
            <UnlikedIcon />
          </button>
          )}
    </>
  )
}

export default LikeButton
