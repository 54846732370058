import React from 'react'
import { getProfilePicFromData, getAltTextFromData } from '../helpers'
import styles from '../styles'

const ProfilePicture = (props) => {
  const { data } = props
  return (
    <img
      alt={getAltTextFromData(data)}
      style={styles.owner.image}
      src={getProfilePicFromData(data)}
    />
  )
}

export default ProfilePicture
