import React from 'react'
import LikeButton from '../../like_button'
import { commentFormatter } from '../helpers'
import styles from '../styles'

const Comment = (props) => {
  const { username, comment } = props

  return (
    <li style={styles.container}>
      <p style={styles.content}>
        <a href={'/users/' + username} style={styles.username}>
          {username}
        </a>

        {commentFormatter(comment)}
      </p>
      <LikeButton />
    </li>
  )
}
export default Comment
