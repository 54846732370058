import React, { useState } from 'react'
import styles from './styles'
import LikeButton from '../like_button'
import { getLikeCount, getDaysSinceTimestamp } from './helpers'

const MetaBlock = (props) => {
  const { data } = props
  const [totalLikes, setTotalLikes] = useState(getLikeCount(data))

  return (
    <div style={styles.container}>
      <div style={styles.stack}>
        <div>{totalLikes} likes</div>
        <div>{getDaysSinceTimestamp(data)}</div>
      </div>
      <LikeButton totalLikes={totalLikes} setTotalLikes={setTotalLikes} />
    </div>
  )
}

export default MetaBlock
