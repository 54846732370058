import styles from './styles';

export function commentFormatter (string) {
  // Check if string contains '#', If so make into a link.
  return string
    .split(/((?:^|\s)(?:#[a-z\d-]+))/gi)
    .filter(Boolean)
    .map((v) => {
      if (v.includes('#')) {
        return (
          <a href={'/tags/' + v} style={styles.hashtags}>
            {v}
          </a>
        )
      } else {
        return <>{v}</>
      }
    })
}
