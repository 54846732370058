import React from 'react'
import { getUsernameFromData } from '../helpers'
import styles from '../styles'

const Username = (props) => {
  return (
    <span style={styles.owner.username}>{getUsernameFromData(props.data)}</span>
  )
}

export default Username
